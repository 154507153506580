import {
  FaArrowDown,
  FaFacebook,
  FaFacebookSquare,
  FaInstagram,
  FaInstagramSquare,
  FaPinterest,
  FaPinterestSquare,
  FaRegClock,
  FaRegUser,
  FaYoutube,
  FaYoutubeSquare,
} from 'react-icons/fa';

import { FaCircleCheck, FaSquareXTwitter, FaXTwitter } from 'react-icons/fa6';

import {
  MdAdd,
  MdArrowForward,
  MdCancel,
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdContentCopy,
  MdDone,
  MdError,
  MdMenu,
  MdNewspaper,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineFileDownload,
  MdOutlineOpenInNew,
  MdPlayCircle,
  MdRemove,
  MdRssFeed,
  MdSearch,
  MdSearchOff,
} from 'react-icons/md';

import { TbMailHeart, TbToolsKitchen } from 'react-icons/tb';
import ArrowFilled from './svg/arrow-filled.svg';
import SliderForwardArrow from './svg/arrow-forward-slider.svg';

import { CgGym } from 'react-icons/cg';

import Bin from './svg/bin.svg';
import Cookie from './svg/cookie.svg';
import ElleAccordion from './svg/elle-accordion.svg';
import Facebook from './svg/facebook.svg';
import FilledArrow from './svg/filledArrow.svg';
import Pinterest from './svg/pinterest.svg';
import PlayIcon from './svg/play.svg';
import Sent from './svg/sent.svg';
import SliderArrow from './svg/sliderArrow.svg';
import TeaserArrow from './svg/teaser-arrow.svg';
import Tiktok from './svg/tiktok.svg';

export const iconsDictionary = {
  add: MdAdd,
  arrowForward: MdArrowForward,
  arrowFilled: ArrowFilled,
  bin: Bin,
  cancel: MdCancel,
  chevronLeft: MdChevronLeft,
  chevronRight: MdChevronRight,
  close: MdClose,
  copy: MdContentCopy,
  done: MdDone,
  download: MdOutlineFileDownload,
  elleAccordion: ElleAccordion,
  error: MdError,
  externalLink: MdOutlineOpenInNew,
  facebook: Facebook,
  filledArrow: FilledArrow,
  facebookRound: FaFacebook,
  facebookSquare: FaFacebookSquare,
  instagram: FaInstagram,
  instagramSquare: FaInstagramSquare,
  menu: MdMenu,
  newspaper: MdNewspaper,
  pinterest: Pinterest,
  pinterestRound: FaPinterest,
  pinterestSquare: FaPinterestSquare,
  play: PlayIcon,
  playCircle: MdPlayCircle,
  remove: MdRemove,
  rssFeed: MdRssFeed,
  search: MdSearch,
  searchOff: MdSearchOff,
  sent: Sent,
  showLess: MdOutlineExpandLess,
  showMore: MdOutlineExpandMore,
  sliderArrow: SliderArrow,
  sliderForwardArrow: SliderForwardArrow,
  tiktok: Tiktok,
  x: FaXTwitter,
  xSquare: FaSquareXTwitter,
  user: FaRegUser,
  youtube: FaYoutube,
  youtubeSquare: FaYoutubeSquare,
  teaserArrow: TeaserArrow,
  cookie: Cookie,
  clock: FaRegClock,
  toolsKitchen: TbToolsKitchen,
  gym: CgGym,
  mailHeart: TbMailHeart,
  circleCheck: FaCircleCheck,
  arrowDown: FaArrowDown,
};
