import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import SvenskdamLogo from '@/svenskdam/public/image/svenskdam-logo.svg';
import { mergeProps } from '@/utils/merge';
import { StandaloneNavigation, StandaloneNavigationProps } from 'base/components/Navigation';
import { Navigation } from 'base/components/Navigation/Navigation';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import { useMobileAppState } from 'lib/hooks/useMobileAppState';
import { showOneTrustConsent } from 'lib/utils/oneTrust';

const logo: StandaloneNavigationProps['logo'] = <SvenskdamLogo />;

const menu: StandaloneNavigationProps['menu'] = (
  <>
    <Navigation.Link href="/" content="Start" aria-label="Länk till startsidan" />
    <Navigation.Link href="/kungligt" content="Kungligt" aria-label="Länk till kategorin Kungligt" />
    <Navigation.Link href="/noje" content="Kändis" aria-label="Länk till kategorin Kändis" />
    <Navigation.Link href="/nobel" content="Nobel" aria-label="Länk till kategorin om Nobelfesten" />
    <Navigation.Link href="/video" content="TV" aria-label="Länk till kategorin TV som är Svensk Dams Video-sida" />
  </>
);

const controls: StandaloneNavigationProps['controls'] = (
  <button type="button" onClick={showOneTrustConsent} aria-label="Knapp för att öppna inställningarna för cookies">
    <Icon name="cookie" options={{ className: 'text-white', size: 'medium' }} />
  </button>
);

const drawerLinkProps = { options: { colors: 'drawer', size: 'drawer', variant: 'drawerPrimary' } };
const drawer: StandaloneNavigationProps['drawer'] = {
  menu: (
    <>
      <Navigation.Menu size="drawer" variant="drawer">
        <Navigation.Link
          href="/kungligt"
          content="Kungligt"
          aria-label="Länk till kategorin Kungligt"
          {...drawerLinkProps}
        />

        <Navigation.Link href="/noje" content="Kändis" aria-label="Länk till kategorin Nöje" {...drawerLinkProps} />

        <Navigation.Link
          href="/kungliga-modebloggen"
          content="Kungligt mode"
          aria-label="Länk till kategorin Kungligt mode"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/nobel"
          content="Nobel"
          aria-label="Länk till kategorin om Nobelfesten"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/video"
          content="TV"
          aria-label="Länk till kategorin TV som är Svensk Dams Video-sida"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/kronika"
          content="Krönikor"
          aria-label="Länk till kategorin Krönikor"
          {...drawerLinkProps}
        />
      </Navigation.Menu>

      <Navigation.Menu size="drawer" variant="drawer">
        <Navigation.Link
          href="https://prenumerera.se/tidningar/svensk-damtidning/?utm_source=svenskdam.se&utm_medium=menu&utm_campaign=svenskdamtidning"
          content="Prenumerera"
          aria-label="Länk till att prenumerera på Svensk Dam"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/nyhetsbrev"
          content="Nyhetsbrev"
          aria-label="Länk till sidan för prenumerera på Svensk Dams nyhetsbrev"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/alla-amnen"
          content="Alla ämnen"
          aria-label="Länk till sidan Alla ämnen där alla kategorier på Svensk Dam visas i alfabetisk ordning"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/svensk-damtidnings-lasarpanel"
          content="Läsarpanel"
          aria-label="Länk till sidan där du kan gå med i Svensk Dams läsarpanel"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/vara-skribenter"
          content="Våra skribenter"
          aria-label="Länk till sidan där alla skribenter för Svensk Dam listas"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/creative-studio"
          content="Creative studio"
          aria-label="Länk till sidan där alla Svensk Dams annonssamarbeten listas"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="#"
          content="Våra hovexperter"
          {...mergeProps({ options: { className: 'pointer-events-none' } }, drawerLinkProps)}
        />

        <Navigation.Link
          href="/vara-skribenter/herman_lindqvist"
          options={{ ...drawerLinkProps.options, variant: 'drawerSecondary' }}
          content={
            <>
              <span className="text-gray-600 text-headline-2xs">Historiker</span> Herman Lindqvist
            </>
          }
        />

        <Navigation.Link
          href="/vara-skribenter/johan_t_lindwall"
          options={{ ...drawerLinkProps.options, variant: 'drawerSecondary' }}
          content={
            <>
              <span className="text-gray-600 text-headline-2xs">Chefredaktör</span> Johan T. Lindwall
            </>
          }
        />

        <Navigation.Link
          href="/vara-skribenter/helena_wiklund"
          options={{ ...drawerLinkProps.options, variant: 'drawerSecondary' }}
          content={
            <>
              <span className="text-gray-600 text-headline-2xs">Hovreporter</span> Helena Wiklund
            </>
          }
        />
      </Navigation.Menu>

      <Navigation.Menu size="drawer" variant="drawer">
        <Navigation.Link
          href="/kontaktaoss"
          content="Kontakta oss"
          aria-label="Länk till sidan för att kontakta Svensk Dam i olika ärenden"
          {...drawerLinkProps}
        />

        <Navigation.Link
          href="/om-oss"
          options={{ ...drawerLinkProps.options, variant: 'drawerSecondary' }}
          content="Om oss"
          aria-label="Länk till sidan om Svensk Dam och dess historia"
        />

        <Navigation.Link
          href="https://svenskdamtidning.ocast.com/"
          options={{ ...drawerLinkProps.options, variant: 'drawerSecondary' }}
          content="Annonsera"
          aria-label="Länk till sidan om du vill annonsera på Svensk Dam"
        />

        <Navigation.Link
          href="/om-cookies"
          options={{ ...drawerLinkProps.options, variant: 'drawerSecondary' }}
          content="Om Cookies"
          aria-label="Länk till information och val gällande cookies på Svensk Dam"
        />

        <Navigation.Link
          href="#"
          options={{
            ...drawerLinkProps.options,
            variant: 'drawerSecondary',
            onClick: (e) => {
              showOneTrustConsent();
              e.preventDefault();
            },
          }}
          content="Hantera preferenser"
          aria-label="Länk till sidan för att hantera dina cookie-preferenser"
        />
      </Navigation.Menu>
    </>
  ),
  toggle: (
    <Navigation.Drawer.Toggle
      options={{
        $segment: {
          className: '!bg-white',
        },
      }}
    />
  ),
};

const soMe: StandaloneNavigationProps['soMe'] = (
  <Navigation.SoMe.Group>
    <Navigation.SoMe.Headline>Följ Svensk Dam</Navigation.SoMe.Headline>

    <Navigation.SoMe
      href="https://www.facebook.com/svenskdamtidning/?locale=sv_SE"
      name="facebook"
      aria-label="Länk till Svensk Dams Facebook-sida"
    />
    <Navigation.SoMe
      href="https://www.instagram.com/svenskdamtidning/"
      name="instagram"
      aria-label="Länk till Svensk Dams Instagram-sida"
    />
    <Navigation.SoMe href="https://x.com/svenskdam" name="x" aria-label="Länk till Svensk Dams X-sida" />
    <Button
      link={{
        href: 'https://news.google.com/publications/CAAiEMOO7LLrA0PM4gJfbc3NYiYqFAgKIhDDjuyy6wNDzOICX23NzWIm?hl=sv&gl=SE&ceid=SE%3Asv',
        target: '_blank',
      }}
      options={{
        size: 'small',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Navigation.SoMe.Group>
);

export const SvenskdamStandaloneNavigation: typeof StandaloneNavigation = () => {
  const { isMobileApp } = useMobileAppState();

  if (isMobileApp) {
    return null;
  }

  return (
    <StandaloneNavigation
      options={{
        className: 'hide-in-mobile-app',
        $search: {
          $submit: {
            options: {
              size: 'medium',
              colors: 'secondary',
            },
          },
        },
      }}
      {...{ logo, menu, controls, drawer, soMe }}
    />
  );
};
